import * as APP from "./app";

// Configurazioni globali
var app = angular.module('app')
  .config(
  ["$provide", '$controllerProvider', '$compileProvider', '$filterProvider', '$httpProvider', 'blockUIConfig', 'moment',
    function ($provide: any, $controllerProvider: any, $compileProvider: any, $filterProvider: any, $httpProvider: angular.IHttpProvider, blockUIConfig: any, moment: any) {
      // lazy controller, directive and service
      app.controller = $controllerProvider.register;
      app.directive = $compileProvider.directive;
      app.filter = $filterProvider.register;
      app.factory = $provide.factory;
      app.service = $provide.service;
      app.constant = $provide.constant;
      app.value = $provide.value;

      delete $httpProvider.defaults.headers.common['X-Requested-With'];

      /**
       * Sezione dedicata alla gestione del block-ui: non blocco tutto in automatico ma solo gli oggetti che decido e manualmente
       */
      // Change the default overlay message: provide a custom template to use
      blockUIConfig.template = '<div class="block-ui-overlay"></div><div class="block-ui-message-container" aria-live="assertive" aria-atomic="true"><div ng-class="$_blockUiMessageClass"><md-progress-circular class="md-warn" md-mode="indeterminate"></md-progress-circular></div></div>';
      // Change the default delay to 100ms before the blocking is visible
      blockUIConfig.delay = 0;
      // Disable automatically blocking of the user interface
      blockUIConfig.autoBlock = false;
      // Disable auto body block
      blockUIConfig.autoInjectBodyBlock = false;
    }
  ])

  .config(['$translateProvider', ($translateProvider: angular.translate.ITranslateProvider) => {
    // Register a loader for the static files
    // So, the module will search missing translation tables under the specified urls.
    // Those urls are [prefix][langKey][suffix].
    $translateProvider.useStaticFilesLoader({
      prefix: '../assets/l10n/',
      suffix: '.json'
    });
    // Tell the module what language to use by default
    $translateProvider.preferredLanguage('it');
    // Tell the module to store the language in the local storage
    $translateProvider.useLocalStorage();
    // Fix console strategy warning
    $translateProvider.useSanitizeValueStrategy(null);

    // $translateProvider.useMissingTranslationHandlerLog();
  }])

  .config(['$mdDateLocaleProvider', 'moment', ($mdDateLocaleProvider: any, moment: any) => {
    // Formato della data per l'md-datepicker
    $mdDateLocaleProvider.formatDate = (value: any) => {
      let date = moment(value);
      if (date.isValid()) {
        return date.format('dd/MM/yyyy');
      }
      return '';
    };
  }])

  // Disabilita il debug di angular per velocizzare produzione
  .config(['$compileProvider', function ($compileProvider: any) {
    $compileProvider.debugInfoEnabled(false);
  }])

  .config(["$provide", "$injector", function ($provide: any, $injector: any) {
    $provide.value('$providerInjector', $injector);
    $provide.value('$provide', $provide);
  }]);
